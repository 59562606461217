import Events from "@lib/constants/events";
import { isInternalUser } from "@lib/utilities/global-utilities";

declare global {
  interface Window {
    ttq: any;
  }
}
const LOGGABLE_EVENTS = [
  Events.PageLoad,
  Events.AnsweredQuestion,
  Events.FinishedQuestion,
] as const;

type LoggableEvent = (typeof LOGGABLE_EVENTS)[number];

// https://ads.tiktok.com/help/article/standard-events-parameters?lang=en
enum TIKTOK_STANDARD_EVENTS {
  ViewContent = "ViewContent",
  ClickButton = "ClickButton",
  SubmitForm = "SubmitForm",
}

// https://ads.tiktok.com/help/article/about-parameters
const MAP_TIKTOK_STANDARD_EVENTS = {
  [Events.PageLoad]: {
    key: TIKTOK_STANDARD_EVENTS.ViewContent,
    value: (params) => ({
      ...params,
      content_name: params.path,
    }),
  },
  [Events.AnsweredQuestion]: {
    key: TIKTOK_STANDARD_EVENTS.ClickButton,
    value: (params) => ({
      ...params,
      content_name: "Answered Question",
    }),
  },
  [Events.FinishedQuestion]: {
    key: TIKTOK_STANDARD_EVENTS.SubmitForm,
    value: (params) => ({
      ...params,
      content_name: "Finished Questions",
    }),
  },
};

export const tiktokEvent = ({ action, ...params }) => {
  if (isInternalUser()) {
    return;
  }
  if (!isLoggableEvent(action)) {
    return;
  }

  // Map our events to TikTok's standard event name and value for ad tracking purposes
  const { eventName, value } = getStandardTikTokEvent(action, params);

  window.ttq?.track(eventName, value);
};

const getStandardTikTokEvent = (action: LoggableEvent, params: any = {}) => {
  const { key, value } = MAP_TIKTOK_STANDARD_EVENTS[action];
  const transformedValue = value(params);

  return {
    eventName: key,
    value: transformedValue,
  };
};

function isLoggableEvent(action: Events): action is LoggableEvent {
  return LOGGABLE_EVENTS.includes(action as LoggableEvent);
}

type AnyFunction = (...args: any[]) => any;
import eventTracker, { Events } from "lib/tracking/event-tracker";

class ErrorTracker {
  private attributes: Record<string, string | number> = {};

  public track(
    error: Error | string,
    attributes?: Record<string, string | number>
  ) {
    const finalAttributes = {
      ...this.attributes,
      ...(attributes ?? {}),
    };

    if (typeof window === "undefined") {
      // runs on server-side
      const newrelic = require("newrelic");
      newrelic.noticeError(error, finalAttributes);
    } else if ((window as any).newrelic) {
      // runs in the browser
      (window as any).newrelic.noticeError(error, finalAttributes);
      eventTracker.track(Events.ErrorEvent, {
        error_message: error.toString(),
        ...finalAttributes,
      });
    }
  }

  public setUserId(id: string | undefined | null) {
    this.setAttribute("userId", id);
  }

  /**
   * Returns an identically typed version of the input function which also performs
   * identically except that we track any errors which may be thrown during its execution.
   * Errors are passed through just as if the original function had been called directly.
   */
  public wrapFunction<Func extends AnyFunction>(
    originalFunction: Func
  ): (...args: Parameters<Func>) => ReturnType<Func> {
    const errorTrackingWrapper = (
      ...args: Parameters<Func>
    ): ReturnType<Func> => {
      try {
        const returnValue = originalFunction(...args);

        // in case the wrapped function is async, we tack on an error handler, since
        // otherwise the try/catch in here will not catch it
        if (returnValue instanceof Promise) {
          returnValue.catch((e) => {
            this.track(e);
          });
        }

        return returnValue;
      } catch (e) {
        this.track(e);
        throw e;
      }
    };

    return errorTrackingWrapper;
  }

  private setAttribute(name: string, value: string | undefined | null) {
    if (value !== undefined && value !== null) {
      this.attributes[name] = value;
    } else if (this.attributes[name]) {
      delete this.attributes[name];
    }
  }
}

const errorTracker = new ErrorTracker();

export default errorTracker;

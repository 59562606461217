import mixpanel from "mixpanel-browser";
import { event } from "@lib/tracking/google-analytics";
import { tiktokEvent } from "@lib/tracking/tiktok-analytics";
import Events from "@lib/constants/events";
import { newTracker, trackStructEvent } from "@snowplow/browser-tracker";
import errorTracker from "@lib/tracking/error-tracker";
import {
  getProductCategory,
  isInternalUser,
} from "@lib/utilities/global-utilities";
import { isProduction } from "@lib/utilities/client-utilities";

type Properties = Record<string, any>;

class EventTracker {
  readonly isInitialized: boolean = false;

  public constructor() {
    const mixpanelToken = isInternalUser()
      ? process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_PREVIEW ||
        process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
      : process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
    if (!this.isInitialized && mixpanelToken) {
      mixpanel.init(mixpanelToken, {
        ignore_dnt: true,
        loaded: () => {},
        api_host: "https://d3fxxnp1gbqot6.cloudfront.net",
      });
      newTracker("sp", "https://prod-sp-collector.perfectrec.com", {
        appId: `perfectrec-${
          process?.env?.NODE_ENV === "production" &&
          typeof window !== "undefined" &&
          !isProduction()
            ? "preview"
            : process?.env?.NODE_ENV === "production" && isInternalUser()
            ? "internal"
            : process?.env?.NODE_ENV
        }`,
        plugins: [],
        discoverRootDomain: true,
      });

      this.isInitialized = true;
    } else {
      this.fail(`MixPanel token not available, so it is disabled`);
    }
  }

  public getDistinctId(): string | null {
    if (this.isInitialized) {
      return mixpanel.get_distinct_id();
    } else {
      this.fail(`EventTracker is not initalized: Could not get user id`);
      return null;
    }
  }

  public setUserId(id: string) {
    if (this.isInitialized) {
      mixpanel.identify(id);
    } else {
      this.fail(`EventTracker is not initalized: Could not set user id`);
    }
  }

  public track(eventName: Events, properties?: Properties) {
    const loggedProperties = {
      product_category: getProductCategory(),
      page_title: document?.title,
      ...properties,
    };
    this.trackCommon(eventName, () => {
      process?.env?.NODE_ENV === "production" &&
        typeof window !== "undefined" &&
        isProduction() &&
        !isInternalUser() &&
        trackStructEvent({
          action: eventName,
          category: "track_event",
          context: Object.keys(loggedProperties).map((key) => ({
            schema:
              "iglu:com.perfectrec/public_key_value_context/jsonschema/1-0-0",
            data: {
              key,
              value:
                typeof loggedProperties[key] === "string"
                  ? loggedProperties[key]
                  : JSON.stringify(loggedProperties[key]),
            },
          })),
        });

      mixpanel.track(eventName, loggedProperties);
      event({
        action: eventName,
        ...loggedProperties,
      });
      tiktokEvent({
        action: eventName,
        ...loggedProperties,
      });
      try {
        if (localStorage?.getItem("debugTracking") === "true") {
          console.log(`Tracking | ${eventName}`, loggedProperties);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    });
  }

  public trackLinks(
    el: string | Element | Element[],
    eventName: Events,
    properties?: Properties | (() => Properties)
  ) {
    this.trackCommon(eventName, () => {
      mixpanel.track_links(el, eventName, properties);
    });
  }

  public setGlobalProperties(properties: Properties) {
    if (this.isInitialized) {
      mixpanel.register(properties);
    } else {
      this.fail(
        `EventTracker is not initalized: Could not set global properties`
      );
    }
  }

  // Private Methods
  private fail(message: string) {
    errorTracker.track(`EventTracker: ${message}`);
  }

  private trackCommon(eventName: Events, tracker: () => void) {
    if (this.isInitialized) {
      tracker();
    } else {
      this.fail(
        `EventTrack is not initialized. Could not fire event ${eventName}`
      );
    }
  }
}

export { Events };
export default new EventTracker();

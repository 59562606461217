import { Subject } from "rxjs";

export interface ErrorNotificationEvent {
  text?: string;
  error?: string;
  ts: number;
  timeout?: number; // milliseconds
}

export const errorNotificationSubject = new Subject<ErrorNotificationEvent>();

import { Fragment, useContext } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { NotificationContext } from "contexts/notification-context-provider";

const Notification = () => {
  const { notification } = useContext(NotificationContext);

  const NotificationXButton = () => {
    return (
      <div className="ml-1 flex-shrink-0 flex">
        <button
          type="button"
          className="bg-gray-200 hover:bg-gray-300 text-sm font-semibold rounded-md py-2 px-3 inline-flex"
          onClick={notification.onClose}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    );
  };

  return (
    <>
      <div className="relative z-40">
        <div className="fixed top-14 left-1/2 -translate-x-1/2 w-full lg:w-fit md:w-fit items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            <Transition
              show={notification.show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-0 opacity-0 sm:translate-y-0 sm:translate-x-0"
              enterTo="translate-y-2 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-center">
                    <div className="flex-1 flex justify-between">
                      <p className="flex-1 mt-2">{notification.text}</p>
                    </div>
                    {notification.onClose && <NotificationXButton />}
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;

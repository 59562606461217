import React from "react";
import Link from "next/link";

interface TrayButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  variant?: "outline" | "borderless" | "solid" | "gray";
  pulse?: boolean;
  className?: string;
  disabled?: boolean;
  ["data-testid"]?: string;
}

const variants = {
  outline: "border border-blue-600 hover:bg-blue-50 text-blue-600",
  borderless: "text-blue-600 hover:bg-blue-50",
  solid: "bg-blue-600 hover:bg-blue-700 border-blue-600 border text-white",
  gray: "bg-gray-200 hover:bg-gray-300 text-slate-900",
};

/**
 * This is the new type of primary button, with a white background and blue
 * outline.
 */
export const PrimaryButton = (props: TrayButtonProps) => {
  const variantClass = variants[props.variant] || variants.outline;

  return (
    <button
      {...props}
      type="button"
      className={`flex justify-center items-center gap-1 px-5 py-2 ${variantClass} rounded-lg font-semibold ${
        props.className || ""
      }`}
    >
      {props.children}
    </button>
  );
};

/**
 * Looks like the PrimaryButton above but is a link.
 */
export const LinkButton = (props: {
  children: React.ReactNode;
  href: string;
  className?: string;
  variant?;
  onClick?: () => void;
  testId?: string;
}) => {
  const variantClass = variants[props.variant] || variants.solid;

  return (
    <Link
      href={props.href}
      className={`${variantClass} px-5 py-2 block rounded-lg text-center ${
        props.className || ""
      }`}
      onClick={props.onClick}
      data-testid={props.testId}
    >
      {props.children}
    </Link>
  );
};

/**
 * This is a gray or gray outlined button that lives in the question tray.
 *
 * @TODO - this is used more generically now, let's rename.
 */
export const TrayButton = (props: TrayButtonProps) => {
  const variants = {
    outline: "border border-gray-400 hover:bg-gray-50",
    solid: "bg-gray-200 hover:bg-gray-300",
  };

  const variantClass = variants[props.variant] || variants.outline;
  const pulseClass = props.pulse
    ? "animate-[glow_1s_ease-in-out_infinite]"
    : "";
  const domProps = { ...props };
  delete domProps.pulse;

  return (
    <button
      {...domProps}
      data-testid={props["data-testid"] || undefined}
      className={`${variantClass} ${pulseClass} font-semibold text-xs py-2 px-3 flex items-center rounded gap-1 ${
        props.className || ""
      } ${props.disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
      {props.children}
    </button>
  );
};

export const ShowMoreBtn = ({
  setExpanded,
  expanded,
  className = "",
}: {
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  expanded: boolean;
  className?;
}) => {
  return (
    <button
      onClick={() => setExpanded(!expanded)}
      className={`border border-blue-500 text-blue-500 font-semibold py-1 px-2 rounded-md text-xs mx-auto block ${className} `}
    >
      {expanded ? "Show less" : "Show more"}
    </button>
  );
};

export const TextButton = ({
  children,
  className = "text-sm",
  ...props
}: {
  children?: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<"button">) => {
  return (
    <button className={`btn-text ${className}`} type="button" {...props}>
      {children}
    </button>
  );
};

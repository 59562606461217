import { NotificationMessage } from "@lib/model/notification-message";
import { createContext, useState } from "react";

export const NotificationContext = createContext<{
  notification: NotificationMessage;
  setNotification: React.Dispatch<React.SetStateAction<NotificationMessage>>;
}>(null);

// This context is for the user notifications, e.g. using the copy-to-clipboard
// utility.
const NotificationContextProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    text: "",
    show: false,
  });

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;

/**
 * This file contains helper functions that have client-side dependencies.
 */
import { useEffect, useState } from "react";
import { Asset } from "contentful";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { Product } from "@lib/model/product.js";
import { defaultTo } from "ramda";
import { createClient } from "contentful";
import throttle from "lodash.throttle";

const menuHeight = 56;

/**
 * Scroll to a supplied ref object.
 */
export const scrollToRef = (
  ref: React.RefObject<HTMLElement>,
  offsetMenu = false
) => {
  const y =
    ref.current.getBoundingClientRect().top +
    window.scrollY -
    (offsetMenu ? menuHeight : 0);
  window.scrollTo({ top: y, behavior: "smooth" });
};

/**
 * Gets a value from local storage after checking whether it is possible.
 *
 * @param key
 * @param parse
 * @returns
 */
export const getSessionStorage = <t>(key: string, parse = true): t => {
  if (!isClient()) return;

  const value = sessionStorage.getItem(key);

  return parse ? JSON.parse(value) : value;
};

/**
 *
 * @param key
 * @param value
 * @returns
 */
export const setSessionStorage = (key: string, value: any) => {
  if (!isClient()) return;

  sessionStorage.setItem(key, JSON.stringify(value));
};

export const productCategorySession = {
  getter(productCategoryConfig: ProductCategoryConfig) {
    return (key) => {
      const data = getSessionStorage(productCategoryConfig.name);
      return data != null && data[key];
    };
  },
  setter(productCategoryConfig: ProductCategoryConfig) {
    return (key: string, value: any) => {
      const data: object = getSessionStorage(productCategoryConfig.name);
      setSessionStorage(productCategoryConfig.name, {
        ...data,
        ...{ [key]: value },
      });
    };
  },
};

/**
 * Gets the height and width of the viewport.
 */
const getWindowDimensions = (): { width: number; height: number } => {
  if (typeof window === "undefined") {
    return {
      width: null,
      height: null,
    };
  }
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

/**
 * Gets the Tailwind breakpoint, based on the current window dimensions.
 */
export const getBreakpoint = (): string => {
  const { width } = getWindowDimensions();

  // This must always match the Tailwind breakpoint values, which are derived
  // from our tailwind.config.json plus the tailwind defaults.
  if (width >= 1024) {
    return "lg";
  } else if (width > 768) {
    return "md";
  } else if (width > 640) {
    return "sm";
  } else {
    return "xs";
  }
};

/**
 * Hook for getting window dimensions.
 *
 * See https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs.
 *
 * @returns
 */
export const useWindowDimensions = (testBreakpoint = null) => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const breakpointOrder = ["xs", "sm", "md", "lg"];
  const [breakpoint, setBreakpoint] = useState(getBreakpoint());
  let aboveBreakpoint = null;

  if (testBreakpoint) {
    const testI = breakpointOrder.indexOf(testBreakpoint);
    const breakpointI = breakpointOrder.indexOf(breakpoint);

    aboveBreakpoint = breakpointI >= testI;
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      setBreakpoint(getBreakpoint());
    }

    const throttledResizer = throttle(handleResize, 300);

    window.addEventListener("resize", throttledResizer);
    return () => window.removeEventListener("resize", throttledResizer);
  }, []);

  return {
    breakpoint,
    windowDimensions,
    aboveBreakpoint,
  };
};

/**
 * Gets the URL to an asset in contentful.
 *
 * @param asset - A Contentful asset object
 * @param size - An object representing height and width of image.
 * @returns
 */
export const getUrlForCmsAsset = (
  asset: Asset,
  size?: { w?: number; h?: number }
) => {
  let url = "https:" + asset.fields.file.url;

  if (size) {
    url +=
      "?fit=fill&" +
      Object.entries(size)
        .map((entry) => `${entry[0]}=${entry[1]}`)
        .join("&");
  }

  return url;
};

export const getServerUrl = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

/**
 * Determines whether the site is running on its production URL.
 */
export const isProduction = () => {
  return isClient() && window.location.hostname === "www.perfectrec.com";
};

/**
 * Determines if the code is running client-side.
 *
 * @returns {boolean} True if the code is running in the browser.
 */
export const isClient = (): boolean => {
  return typeof window !== "undefined";
};

/**
 * Gets the window object if the code is running client-side, otherwise null.
 *
 * @returns window object or null
 */
export const getClientSafeWindow = (): (Window & typeof globalThis) | null => {
  return isClient() ? window : null;
};

export const getDisplayScore = (sheetScore: string) => {
  let score = Number(sheetScore);
  if (!Number.isInteger(score)) {
    score = Math.round(score * 10) / 10;
  }
  return score.toString();
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeWordsExceptCommon = (str: string) => {
  const commonArticles = [
    "and",
    "or",
    "but",
    "the",
    "a",
    "an",
    "at",
    "by",
    "for",
    "in",
    "of",
    "on",
    "to",
    "up",
    "with",
  ];

  return str.replace(/\b\w+\b/g, (word) => {
    return commonArticles.includes(word.toLowerCase())
      ? word
      : word.charAt(0).toUpperCase() + word.slice(1);
  });
};

/**
 * Replaces n spaces (from the right) of a string with the non-breaking space character.
 * @param string - Input string.
 * @param n - Number of spaces from the right of the string.
 * @returns String with non-breaking space character at all n spaces from the right.
 */
export const addNonBreakSpace = (
  string: string,
  n: number,
  characterLimit?: number
) => {
  const arr = string.split(" ");
  if (arr.length === 1) {
    return string;
  }
  const breakpoint = arr.length - n;
  const left = arr.slice(0, breakpoint).join(" ");
  const right = arr.slice(breakpoint).join("\u00a0");
  if (characterLimit && right.length > characterLimit) {
    return addNonBreakSpace(string, n - 1, characterLimit);
  }
  return `${left}\u00a0${right}`;
};

/**
 * Get a Contentful client that can used in a browser.
 */
export const createContentfulClient = () => {
  const client = createClient({
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_API_ACCESS_TOKEN,
  });

  return client;
};

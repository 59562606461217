import { isInternalUser } from "@lib/utilities/global-utilities";

const GTM_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
const GTM_ID_PREVIEW = process.env.NEXT_PUBLIC_GA_TRACKING_ID_PREVIEW;

declare global {
  interface Window {
    gtag: any;
  }
}

export const getGTM_ID = () => {
  if (isInternalUser()) {
    return GTM_ID_PREVIEW;
  }
  return GTM_ID;
};

export const pageview = (url) => {
  window.gtag("config", getGTM_ID(), {
    page_path: url,
  });
};

export const event = ({ action, ...params }) => {
  window.gtag("event", action, {
    ...params,
  });
};

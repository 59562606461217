import Script from "next/script";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getGTM_ID, pageview } from "@lib/tracking/google-analytics";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import "styles/globals.css";
import ErrorNotification from "@components/modal/error-notification";
import Head from "next/head";
import Notification from "@components/modal/notification";
import NotificationContextProvider from "contexts/notification-context-provider";
import { SWRConfig } from "swr";
import { dontEmbedAds, isInternalUser } from "@lib/utilities/global-utilities";
import errorTracker from "@lib/tracking/error-tracker";
import { TIKTOK_PIXEL_ID, META_PIXEL_ID } from "@lib/constants/pixels";
import NextNProgress from "nextjs-progressbar";
import App from "next/app";

const persistentQueryParams = ["internal", "mlEndpoint", "it", "embedAds"];

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();

  useEffect(() => {
    EventTracker.track(Events.PageLoad, {
      path: router.asPath,
      component: pageProps.component,
    });
    router.events.on("routeChangeComplete", pageview);
    router.events.on("hashChangeComplete", pageview);
    return () => {
      router.events.off("routeChangeComplete", pageview);
      router.events.off("hashChangeComplete", pageview);
    };
  }, [router.events, router.asPath, pageProps.component]);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      const currentQueryParams = router.query;
      const splitUrl = url.split("?");
      const queryParams = new URLSearchParams(
        splitUrl.length === 2 ? splitUrl[1] : ""
      );
      let modified = false;
      persistentQueryParams.forEach((key) => {
        const oldParam = currentQueryParams[key];
        if (typeof oldParam === "string" && queryParams.get(key) !== oldParam) {
          modified = true;
          queryParams.set(key, oldParam);
        }
      });
      if (modified) {
        router.replace(`${splitUrl[0]}?${queryParams.toString()}`, undefined, {
          shallow: true,
        });
        return false;
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    window.onbeforeunload = () => {
      EventTracker.track(Events.PageClose, { path: router.asPath });
    };
  });

  useEffect(() => {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;

    const dark = "/dark.ico";
    const light = "/light.ico";

    favicon.href = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? light
      : dark;

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        favicon.href = event.matches ? light : dark;
      });
  }, []);

  // Track entry data.
  useEffect(() => {
    const storedEntryMetadata = sessionStorage.getItem("entryMetadata");

    if (!storedEntryMetadata) {
      const entryMetadata = {
        entryPath: window.location.pathname,
        referrer: document.referrer || "unknown",
      };
      const searchParams = new URLSearchParams(window.location.search);
      const trackParams = [
        "utm_campaign",
        "utm_medium",
        "utm_source",
        "utm_term",
        "utm_content",
      ];

      trackParams.forEach((param) => {
        if (searchParams.get(param)) {
          entryMetadata[param] = searchParams.get(param);
        }
      });

      sessionStorage.setItem("entryMetadata", JSON.stringify(entryMetadata));
    }
  }, []);

  return (
    <SWRConfig
      value={{
        onError: (error, useSWRkey) => {
          errorTracker.track(error);
        },
        shouldRetryOnError: false,
      }}
    >
      <NotificationContextProvider>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
          <meta
            name="google-site-verification"
            content="WhPzQRSMbHmZKxNfXnz3SJiK-j9DXtDlWUn_VMcwcZY"
          />
        </Head>
        {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
        <Script
          id="newrelicScript"
          src="/newrelicBrowser.js"
          strategy="beforeInteractive"
        />
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${getGTM_ID()}`}
        />
        <Script
          strategy="afterInteractive"
          src={`https://tally.so/widgets/embed.js`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${getGTM_ID()}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
        {!dontEmbedAds() && (
          <Script
            id="raptive-header-test"
            crossOrigin="anonymous"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w, d) {
                w.adthrive = w.adthrive || {};
                w.adthrive.cmd = w.adthrive.cmd || [];
                w.adthrive.plugin = 'adthrive-ads-manual';
                w.adthrive.host = 'ads.adthrive.com';
                var s = d.createElement('script');
                s.async = true;
                s.referrerPolicy = 'no-referrer-when-downgrade';
                s.src =
                  'https://' + w.adthrive.host + '/sites/65fb16e6404ea745a7da72e6/ads.min.js?referrer=' +
                  w.encodeURIComponent(w.location.href) +
                  '&cb=' +
                  (Math.floor(Math.random() * 100) + 1);
                var n = d.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(s, n);
              })(window, document);
            `,
            }}
            data-no-optimize="1"
            data-cfasync="false"
          />
        )}
        {/* Disable tiktok and facebook pixels */}
        {false && !isInternalUser() && (
          <>
            <Script
              id="tiktok-pixel"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `!function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

                  ttq.load('${TIKTOK_PIXEL_ID}');
                  ttq.page();
                }(window, document, 'ttq');`,
              }}
            />

            <Script
              id="meta-pixel"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${META_PIXEL_ID}');
                fbq('track', 'PageView');
              `,
              }}
            />
          </>
        )}
        {!pageProps?.component?.toString()?.endsWith("-recommendation") && (
          <NextNProgress
            color="rgb(37, 99, 235)"
            options={{ showSpinner: false }}
          />
        )}
        <Component {...pageProps} />
        <Notification />
        <ErrorNotification />
      </NotificationContextProvider>
    </SWRConfig>
  );
};

export default MyApp;

import { PrimaryButton } from "@components/common/button";
import LoadingIcon from "@components/svgs/loading-icon";
import { Transition } from "@headlessui/react";
import { errorNotificationSubject, ErrorNotificationEvent } from "@lib/events";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";

export interface NotificationProps {
  children?: React.ReactNode;
}

const ErrorNotification = () => {
  const defaultText = "There was an error loading the page.";
  const router = useRouter();

  const [errorEvent, setErrorEvent] = useState<ErrorNotificationEvent>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const errorNotifications = errorNotificationSubject.subscribe({
      next: (ev) => {
        ev.text = ev.text || defaultText;
        setErrorEvent(ev);
      },
    });
    return () => {
      errorNotifications.unsubscribe();
    };
  }, []);

  const refreshPage = () => {
    setLoading(true);
    router.reload();
  };

  return (
    <>
      {errorEvent && (
        <div>
          <div className="fixed top-14 left-1/2 -translate-x-1/2 w-full md:w-auto flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
              <Transition
                show={true}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-0 opacity-0 sm:translate-y-0 sm:translate-x-0"
                enterTo="translate-y-2 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="max-w-sm w-full bg-red-200 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="p-4">
                    <div className="grid grid-cols-3 ">
                      <div className="col-span-3 md:col-span-2 flex-1 flex justify-between mb-2 md:mb-0">
                        <p className="flex-1">
                          {errorEvent.text} Please refresh the page and try
                          again.
                        </p>
                      </div>
                      <div className="col-span-3 md:col-span-1 flex items-center justify-center w-full">
                        <PrimaryButton
                          type="button"
                          className="text-sm w-full md:w-auto"
                          onClick={refreshPage}
                        >
                          Refresh {loading && <LoadingIcon />}
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorNotification;

enum Events {
  PageLoad = "Page Load",
  PageChange = "Page Change",
  PageChangeHash = "Page Change Hash",
  PageClose = "Page Close",
  AnsweredQuestion = "Question Answer",
  SkippedQuestion = "Question Skip",
  BackQuestion = "Question Back",
  FinishedQuestion = "Questions Finish",
  StartedOver = "Questions Start Over",
  RejectedRecommendation = "Recommendation Reject",
  UndoRejectedRecommendation = "Undo Recommendation Reject",
  OpenedFeedbackForm = "Feedback Form Open",
  SubscribedToList = "List Subscribe",
  ProvidedRejectReason = "Provide reject reasons",
  SubmittedProductSuggestion = "Submitted product suggestion",
  ShareStarted = "Started native sharing",
  ShareFailed = "Naive share aborted or failed",
  ProductCardToBack = "Product Card Flip To Back",
  ProductCardToFront = "Product Card Flip To Front",
  ModalOpen = "Modal Open",
  PurchaseModalOpen = "Purchase Modal Open",
  RetailerVisit = "Retailer Visit",
  CompareModalOpen = "Compare Modal Open",
  CompareModalOpenSharePage = "Compare Modal Open in Share Page",
  ConfidenceModalOpen = "Confidence Modal Open",
  PageScroll = "Page Scroll",
  DoneRecSwitch = "Done Page Rec Changed",
  DoneGraphInteract = "Done Page Graph Data Selected",
  DoneBudgetInteract = "Done Page Budget Option Changed",
  ReportedIncorrectPricing = "Reported incorrect pricing",
  ReportedIncorrectInfo = "Reported incorrect information",
  DonePageProductCardClick = "Done Page Product Card Clicked",
  ViewAllProductSelect = "Done Page View All Modal Product Select Button Clicked",
  VsPageGetRecsClick = "Versus Page Get Recommendation Link Clicked",
  VsPageProductSelect = "Versus Page Product Select",
  CompareIndexProductSearch = "Search Product On Compare Index",
  CompareIndexProductSelect = "Select Product on Compare Index",
  CategoryIndexProductSearch = "Search Product On Category Index",
  CategoryIndexProductSelect = "Select Product on Category Index",
  VsPageBrowseProductSearch = "Search Product on Vs Page Browse Section",
  VsPageBrowseProductSelect = "Select Product on Vs Page Browse Section",
  VsPageBrowseScenarioPillClick = "Clicked Scenario Pill on Vs Page Browse Section",
  BrandFilterShowBrands = "Brand Filter Show Brands",
  BrandFilterBackToFilters = "Brand Filter Back to Regular Filters",
  MultipleChoiceToggleAnswer = "Questions Toggle Multiple Choice Answer",
  RecommenderModelResponse = "Recommender Model Response",
  RecommenderGetStarted = "Recommender Get Started",
  RecommenderPreviewRecsCtaClick = "Recommender Clicked Preview Recs CTA",
  RecommenderCardTriedInteract = "Recommender tried card interact",
  RecommenderReflowUiOpen = "Recommender Reflow UI Open",
  RecommenderReflowUiStartOver = "Recommender Reflow UI Start Over",
  RecommenderReflowUiReturn = "Recommender Reflow UI Return",
  ErrorEvent = "Error Event",
  AnswerConfirmationShown = "Answer Confirmation Shown",
  AnswerConfirmationAccepted = "Answer Confirmation Accepted",
  CompareProductChange = "Compare Product Change",
  CompareProductSearch = "Compare Product Search",
  ProductDetailProductChange = "Product Detail Page Product Change",
  ProductDetailProductSearch = "Product Detail Page Product Search",
  SessionStorageError = "Session Storage Error",
  MobileSiteNavOpen = "Mobile Site Side Navigation Open",
  MobileSiteNavClose = "Mobile Site Side Navigation Close",
  DonePageNavMenuShown = "Mobile Site Done Page Floating Navigation Menu Shown",
  DonePageNavSectionsToggle = "Mobile Site Done Page Floating Navigation Sections Dropdown Toggle",
  DonePageNavCurrentProductClick = "Mobile Site Done Page Floating Navigation Current Product Click",
  DonePageNavSectionsItemClick = "Mobile Site Done Page Floating Navigation Sections Item Click",
  ModalRecommenderCtaClick = "Modal recommender link CTA click",
  FirstQuestionShown = "Recommender First Question Shown",
  FilterCategoryOpen = "Filter Category Open",
  BadRecsOnDonePage = "Bad Recommendations On Done Page",
  DetailsPageSimilarProductClick = "Product Details Page Similar Product Click",
  DonePageSectionView = "Done Page Section Bottom Scrolled into View",
  EmailModalOpen = "Email Modal Shown",
  RecommenderModalOpen = "Recommender Modal Shown",
  InvitationToExploreCta = "Invitation to Explore CTA",
  VsPageReviewCtaClick = "Vs Page Review CTA Click",
  EvenBetterProductCtaClick = "Even Better Product CTA Click",
  FirstQuestionVsPageClick = "First Question Vs Page Click",
  TopCategoryNavClick = "Top Category Nav Clicked",
  PriceAlertsSubscribe = "Subscribed to Price Alert",
  PriceAlertsUnsubscribe = "Unsubscribed from Price Alert",
}

export default Events;

import { Product } from "@lib/model/product";
import { ResponseSetWithResponses } from "@lib/model/response-set";

export const isInternalUser = (cookies?: any) => {
  const hasWindowInternal =
    typeof window !== "undefined" && location?.search?.includes("internal");
  const hasCookieInternal =
    cookies?.internal === "true" ||
    (typeof document !== "undefined" &&
      document?.cookie?.includes("internal=true"));
  return hasWindowInternal || hasCookieInternal;
};

export const isWindowInternalUser = () => {
  const hasWindowInternal =
    typeof window !== "undefined" && location?.search?.includes("internal");
  return hasWindowInternal;
};

export const dontEmbedAds = () => {
  const dontEmbedAds =
    typeof window !== "undefined" &&
    location?.search?.includes("embedAds=false");
  return dontEmbedAds;
};

export const getProductCategory = () => {
  try {
    const path = typeof window !== "undefined" && location?.pathname;
    let potentialCategory = path?.split("/")[2];
    if (["products", "compare"].includes(potentialCategory)) {
      potentialCategory = path?.split("/")[3];
    }
    const resolvedCategory =
      potentialCategory.split("-").length > 1
        ? potentialCategory.split("-")[1]
        : potentialCategory;
    return resolvedCategory || "unknown";
  } catch (e) {
    return "unknown";
  }
};

/**
 * Function for getting comma separated bad rec state
 */
export const getBadRecs = (
  modelInput: { query: string[]; filters: string[] },
  topProducts: Product[],
  activeResponseSet: ResponseSetWithResponses,
  productCategoryName: string,
  mlEndpoint?: string
): string => {
  if (!modelInput) return "";

  // Function to escape commas by wrapping values with commas in quotes
  const escapeCommas = (value: string) =>
    value.includes(",") ? `"${value}"` : value;

  const filterText =
    modelInput.filters.length > 0
      ? modelInput.filters.map(escapeCommas).join("|")
      : "";
  const queryText = modelInput.query.map(escapeCommas).join(",");

  const datetime = new Date(Date.now()).toISOString().split(".")[0];
  const productsText = topProducts
    .map((product) => `${product.fullName}`)
    .join(",");

  const textToClip =
    queryText +
    "," +
    filterText +
    "," +
    productsText +
    "," +
    datetime +
    "," +
    `https://www.perfectrec.com/routedone/${productCategoryName}?responses=${encodeURIComponent(
      Buffer.from(
        JSON.stringify(
          activeResponseSet.responses.map((r) => {
            return { answerIds: r.answerIds, questionId: r.questionId };
          })
        ),
        "utf-8"
      ).toString("base64")
    )}${mlEndpoint ? `&mlEndpoint=${mlEndpoint}` : ""}`;

  return textToClip;
};
